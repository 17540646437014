<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    ></div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <user-dropdown />
    </b-navbar-nav>
    <div style="display: flex">
      <div v-if="fullScreen" style="cursor: pointer" @click="cancelFullScreen">
        <img style="width: 24px; height: 24px" :src="nfullscn" />
      </div>
      <div v-else style="cursor: pointer" @click="handleFullScreen">
        <img style="width: 24px; height: 24px" :src="fullscn" />
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import UserDropdown from "./components/UserDropdown.vue";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      fullScreen: false,
      fullscn:
        "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjY5OTYwMjExNTQ5IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2OTAiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PHBhdGggZD0iTTg1LjMzMzMzMyA2ODIuNjY2NjY3djEyOGExMjggMTI4IDAgMCAwIDEyOCAxMjhoMTI4YTQyLjY2NjY2NyA0Mi42NjY2NjcgMCAwIDAgMC04NS4zMzMzMzRIMjEzLjMzMzMzM2E0Mi42NjY2NjcgNDIuNjY2NjY3IDAgMCAxLTQyLjY2NjY2Ni00Mi42NjY2NjZ2LTEyOGE0Mi42NjY2NjcgNDIuNjY2NjY3IDAgMCAwLTg1LjMzMzMzNCAweiBtNTk3LjMzMzMzNCAyNTZoMTI4YTEyOCAxMjggMCAwIDAgMTI4LTEyOHYtMTI4YTQyLjY2NjY2NyA0Mi42NjY2NjcgMCAwIDAtODUuMzMzMzM0IDB2MTI4YTQyLjY2NjY2NyA0Mi42NjY2NjcgMCAwIDEtNDIuNjY2NjY2IDQyLjY2NjY2NmgtMTI4YTQyLjY2NjY2NyA0Mi42NjY2NjcgMCAwIDAgMCA4NS4zMzMzMzR6IG0yNTYtNTk3LjMzMzMzNFYyMTMuMzMzMzMzYTEyOCAxMjggMCAwIDAtMTI4LTEyOGgtMTI4YTQyLjY2NjY2NyA0Mi42NjY2NjcgMCAwIDAgMCA4NS4zMzMzMzRoMTI4YTQyLjY2NjY2NyA0Mi42NjY2NjcgMCAwIDEgNDIuNjY2NjY2IDQyLjY2NjY2NnYxMjhhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMCA4NS4zMzMzMzQgMHpNMzQxLjMzMzMzMyA4NS4zMzMzMzNIMjEzLjMzMzMzM2ExMjggMTI4IDAgMCAwLTEyOCAxMjh2MTI4YTQyLjY2NjY2NyA0Mi42NjY2NjcgMCAwIDAgODUuMzMzMzM0IDBWMjEzLjMzMzMzM2E0Mi42NjY2NjcgNDIuNjY2NjY3IDAgMCAxIDQyLjY2NjY2Ni00Mi42NjY2NjZoMTI4YTQyLjY2NjY2NyA0Mi42NjY2NjcgMCAwIDAgMC04NS4zMzMzMzR6IiBwLWlkPSIyNjkxIiBmaWxsPSIjNzM2N2YwIj48L3BhdGg+PC9zdmc+",
      nfullscn:
        "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjY5OTYwMjkzNzg0IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI5MzYiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PHBhdGggZD0iTTcwNCA4NjR2LTk2YzAtNTQuNCA0MS42LTk2IDk2LTk2aDk2YzE5LjIgMCAzMi0xMi44IDMyLTMycy0xMi44LTMyLTMyLTMyaC05NmMtODkuNiAwLTE2MCA3MC40LTE2MCAxNjB2OTZjMCAxOS4yIDEyLjggMzIgMzIgMzJzMzItMTIuOCAzMi0zMnogbS02NC03MDR2OTZjMCA4OS42IDcwLjQgMTYwIDE2MCAxNjBoOTZjMTkuMiAwIDMyLTEyLjggMzItMzJzLTEyLjgtMzItMzItMzJoLTk2Yy01NC40IDAtOTYtNDEuNi05Ni05NnYtOTZjMC0xOS4yLTEyLjgtMzItMzItMzJzLTMyIDEyLjgtMzIgMzJ6IG0tMjU2IDcwNHYtOTZjMC04OS42LTcwLjQtMTYwLTE2MC0xNjBoLTk2Yy0xOS4yIDAtMzIgMTIuOC0zMiAzMnMxMi44IDMyIDMyIDMyaDk2YzU0LjQgMCA5NiA0MS42IDk2IDk2djk2YzAgMTkuMiAxMi44IDMyIDMyIDMyczMyLTEyLjggMzItMzJ6IG0tNjQtNzA0djk2YzAgNTQuNC00MS42IDk2LTk2IDk2aC05NmMtMTkuMiAwLTMyIDEyLjgtMzIgMzJzMTIuOCAzMiAzMiAzMmg5NmM4OS42IDAgMTYwLTcwLjQgMTYwLTE2MHYtOTZjMC0xOS4yLTEyLjgtMzItMzItMzJzLTMyIDEyLjgtMzIgMzJ6IiBwLWlkPSIyOTM3IiBmaWxsPSIjNzM2N2YwIj48L3BhdGg+PC9zdmc+",
    };
  },
  methods: {
    handleFullScreen() {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
        this.fullScreen = true;
      } else if (document.webkitrequestFullScreen) {
        document.webkitrequestFullScreen();
        this.fullScreen = true;
      } else if (document.mozrequestFullScreen) {
        document.mozrequestFullScreen();
        this.fullScreen = true;
      }
    },
    cancelFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        this.fullScreen = false;
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
        this.fullScreen = false;
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        this.fullScreen = false;
      } else if (document.msExitFullScreen) {
        document.msExitFullScreen();
        this.fullScreen = false;
      }
    },
  },
};
</script>
