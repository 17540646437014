/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
import device from './device'
import event from './event'
import auth from './auth'
import label from './label'
import custody from './custody'
import dashboard from './dashboard'
import group from './group'
import { getUserData } from '@/auth/utils'
// Array of sections
// let json = window.localStorage.get('permissionList')
// let menu = JSON.parse(json)
// console.log('menu-h',menu)
let menu = [
]

const userData = getUserData()
if (userData && userData.urlPermissionTree.length > 0) {
  menu = userData.urlPermissionTree
}
console.log(menu)
// export default [...device,...event,...auth,...label,...dashboard]
export default menu
